import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd'

import './style.scss'

class HomePage extends React.Component {
  render() {
    return (
      <>
        <Row className="pd-introduction">
          <Col xs={24}>
            <img
              className="pd-margin-bottom-xl"
              src={`${process.env.PUBLIC_URL}/img/banner-1.jpg`}
              alt="Sample Product"
            />
          </Col>
          <Col xs={{ span: 18, offset: 3 }} lg={{ span: 5, offset: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/sample-product.png`}
              alt="Sample Product"
            />
          </Col>
          <Col xs={24} lg={{ span: 14, offset: 1 }}>
            <h3>Non Woven Bag Manufacturer</h3>
            <span>
              Wilco Spunbond was established in 2013 with a sophisticated
              production machine capable of making the latest spunbond bag
              models. We have produced various types of non woven bags with
              designs and sizes that can be tailored to your needs. There are
              also several bag models with new innovations that are only
              available at Wilco. Wilco Spunbond prioritizes quality, service
              and customer satisfaction.
            </span>
            <div className="pd-margin-top-lg">
              <Link to="/about">
                <Button size="large">About Us</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" className="pd-quality">
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/eco.png`}
                  style={{ width: 30 }}
                  alt="Quality"
                />
              </div>
              <div>
                <h1>Reusable</h1>
                <span>
                  The usage of non-woven bags fit in the concept of reduced and
                  reused. We need to reduced using one-time use plastic bag and
                  reuse the bags.
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  className="pd-margin-top-sm"
                  src={`${process.env.PUBLIC_URL}/img/icon/durable.png`}
                  style={{ width: 30 }}
                  alt="Quality"
                />
              </div>
              <div>
                <h1>Durable</h1>
                <span>
                  Non-woven bags can be reused several times because of its
                  strength and durability.
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/waterproof.png`}
                  style={{ width: 30 }}
                  alt="Quality"
                />
              </div>
              <div>
                <h1>Non-toxic</h1>
                <span>
                  Non woven materials are widely used in mask. They are non
                  toxic and non bacteria since they are made from spun-bonded
                  fibers.
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pd-traction">
          <Col xs={24}>
            <div className="pd-traction-title">
              <h2 className="pd-margin-top-lg  pd-margin-bottom-xl">
                Trusted by over 1000+ Brands
              </h2>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/adidas.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/bca.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/pizzahut.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/puma.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/samsung.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/shell.png`}
              alt="Brand Logo"
            />
          </Col>
        </Row>
        <Row className="pd-product">
          <Col xs={24} lg={{ span: 8, offset: 2 }}>
            <img
              className="pd-margin-bottom-xl"
              src={`${process.env.PUBLIC_URL}/img/dyob.jpg`}
              alt="Design Your Own Bag"
            />
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-product-title">DYOB</div>
            <div className="pd-product-subtitle pd-margin-bottom-lg">
              Design Your Own Bag
            </div>
            <span>
              We have produced various types of non woven bags with designs and
              sizes that can be tailored to your needs. There are also several
              bag models with new innovations that are only available at Wilco.
            </span>
            <div className="pd-margin-top-lg">
              <Link to="/contact">
                <Button size="large">Request Quote</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default HomePage
