import React, { useState } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import Menu from './components/menu'
import Footer from './components/footer'

import HomePageEN from './pages/home'
import HomePageID from './pages/home/id'
import ProductsPageEN from './pages/products'
import ProductsPageID from './pages/products/id'
import ContactPageEN from './pages/contact'
import ContactPageID from './pages/contact/id'
import FAQPageEN from './pages/faq'
import FAQPageID from './pages/faq/id'
import AboutPageEN from './pages/about'
import AboutPageID from './pages/about/id'

import 'antd/dist/antd.css'
import './App.scss'

const LANGUAGE = {
  EN: 'en',
  ID: 'id',
}

export const LangContext = React.createContext(LANGUAGE.EN)

function App() {
  const [lang, setLang] = useState(LANGUAGE.EN)

  function toggleLang() {
    if (lang === LANGUAGE.EN) {
      setLang(LANGUAGE.ID)
    } else {
      setLang(LANGUAGE.EN)
    }
  }

  return (
    <LangContext.Provider value={{ lang, toggleLang }}>
      <HashRouter>
        <Menu />
        <Switch>
          <Route
            exact
            path="/"
            component={lang === LANGUAGE.EN ? HomePageEN : HomePageID}
          />
          <Route
            exact
            path="/products"
            component={lang === LANGUAGE.EN ? ProductsPageEN : ProductsPageID}
          />
          <Route
            exact
            path="/contact"
            component={lang === LANGUAGE.EN ? ContactPageEN : ContactPageID}
          />
          <Route
            exact
            path="/faq"
            component={lang === LANGUAGE.EN ? FAQPageEN : FAQPageID}
          />
          <Route
            exact
            path="/about"
            component={lang === LANGUAGE.EN ? AboutPageEN : AboutPageID}
          />
        </Switch>
        <Footer />
      </HashRouter>
    </LangContext.Provider>
  )
}

export default App
