import React from 'react'
import { Button, Row, Col } from 'antd'

import './style.scss'

class ContactPage extends React.Component {
  render() {
    return (
      <Row className="pd-contact">
        <Col xs={24} lg={{ span: 12, offset: 2 }} className="pd-margin-top-xl">
          <div className="pd-contact-title">Contact Us</div>
          <span>Leave a message and we will reply it as soon as possible</span>
          <form
            action="https://formspree.io/cs@wilcospunbond.com"
            className="pd-margin-top-md"
            method="POST">
            <div>
              <input id="name" name="name" placeholder="Name" type="text" />
            </div>
            <div>
              <input id="email" name="email" placeholder="Email" type="email" />
            </div>
            <div>
              <textarea
                id="message"
                name="message"
                placeholder="Leave a message.."
                type="text"
              />
            </div>
            <Button htmlType="submit">Send</Button>
          </form>
        </Col>
        <Col xs={24} lg={{ span: 6, offset: 2 }} className="pd-margin-top-2xl">
          <div className="pd-margin-bottom-lg">
            <iframe
              title="Google Map"
              width="600"
              height="500"
              src="https://maps.google.com/maps?q=Rukan%20Golflake%20Residence%20Venice%20Blok%20A&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              style={{ width: '100%', height: '300px' }}
            />
          </div>
          <h1>Factory</h1>
          <div>
            Jl. Perancis Raya No. 2 Pergudangan Pantai Indah Dadap, Dadap
            Tangerang
          </div>
          <h1 className="pd-margin-top-lg">Office</h1>
          <div>Rukan Golflake Residence Venice Blok A</div>
          <div>
            Jl. Kalam Raya Outer Ring Road, Cengkareng, Kota Jakarta Barat,
            Daerah Khusus Ibukota Jakarta 10730
          </div>
          <div className="pd-margin-top-md">Email: cs@wilcospunbond.com</div>
          <div>Phone: 021-29526883</div>
          <div>Mobile: 0821-1396-0066</div>
          <div className="pd-margin-top-md pd-margin-bottom-md">
            Welcome to Wilco Spunbond. Please use any of the WhatsApp contacts
            below if you're not yet connected with us.
          </div>
          <div>
            WhatsApp (1):{' '}
            <a
              href="https://wa.me/6281219624222"
              target="_blank"
              rel="noopener noreferrer">
              0812-1305-1066
            </a>
          </div>
          <div>
            WhatsApp (2):{' '}
            <a
              href="https://wa.me/6281213051066"
              target="_blank"
              rel="noopener noreferrer">
              0812-1962-4222
            </a>
          </div>
          <div>
            WhatsApp (3):{' '}
            <a
              href="https://wa.me/6281269424662"
              target="_blank"
              rel="noopener noreferrer">
              0812-6942-4662
            </a>
          </div>
        </Col>
      </Row>
    )
  }
}

export default ContactPage
