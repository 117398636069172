import React from 'react'
import { Carousel, Row, Col } from 'antd'

import './style.scss'

class AboutPage extends React.Component {
  render() {
    return (
      <div className="pd-about">
        <Row>
          <Col xs={24}>
            <img
              src={`${process.env.PUBLIC_URL}/img/productions/1.JPG`}
              alt="Production"
            />
          </Col>
          <Col
            xs={24}
            lg={{ span: 20, offset: 2 }}
            className="pd-margin-top-xl">
            <div className="pd-about-title">About Us</div>
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-content">
              Wilco Spunbond was established in 2013 with a sophisticated
              production machine capable of making the latest spunbond bag
              models. We have a production capacity of 5,000,000pcs / month. We
              have produced various types of non woven bags with designs and
              sizes that can be tailored to your needs. If you need further
              information. Please contact our marketing.
            </div>
            <div className="pd-about-content">
              There are also several bag models with new innovations that are
              only available at Wilco. Bag models can be seen in the catalog
              section of this website. Wilco Spunbond prioritizes quality,
              service and customer satisfaction. We feel responsible for
              preserving the environment for the next generation.
            </div>
            <div className="pd-about-content">
              <h3>Our Value</h3>
              <strong>"MILKK"</strong> Motivasi, Integritas, Loyal, Komitmen,
              Kerjasama (Motivation, Intengrity, Loyalty, Commitment,
              Cooperation)
            </div>
          </Col>
          <Col xs={24} lg={{ span: 9, offset: 1 }}>
            <Carousel autoplay>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/2.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/3.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/4.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/5.JPG`}
                  alt="Production"
                />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">Vision</div>
            <div className="pd-about-content">
              "To be an international-scale and leading-edge bag manufacturing
              company in innovation while providing the best service to the
              customers"
            </div>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">Mission</div>
            <div className="pd-about-content">
              <ol className="pd-about-content__list">
                <li>
                  Produce quality products and the best service for all
                  customers on a small or large scale.
                </li>
                <li>Improve welfare and self-development for each employee.</li>
                <li>
                  High market development each year and has a solid distribution
                  channel at the national and international level.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">Why Choose Wilco Spunbond?</div>
            <div className="pd-about-content">
              <ol className="pd-about-content__list">
                <li>
                  We sell bags made of spunbond/ non woven materials with a
                  variety of colors and can also be printed in accordance with
                  the wishes and promotional activities of your business.
                </li>
                <li>We provide services for retailers and wholesalers.</li>
                <li>Cheap and competitive prices.</li>
                <li>
                  Quality assurance and service and delivery throughout
                  Indonesia.
                </li>
                <li>
                  The advantages of waterproof bags, durable and not easily
                  tangled.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default AboutPage
