import React from 'react'
import { Carousel, Row, Col } from 'antd'

import './style.scss'

const colorOptions = [
  '#f72b2a',
  '#2a32b3',
  '#f6f82b',
  '#97d6f7',
  '#f8912c',
  '#2b834c',
  '#96805e',
  '#2c5bf7',
  '#932af6',
  '#f82ce7',
  '#8e2c2c',
  '#3ef82b',
  '#2b2b28',
]

class ProductsPage extends React.Component {
  handleColorClick = (index = 0) => () => {
    this.slider.goTo(index, true)
  }

  render() {
    return (
      <div className="pd-products">
        <Row type="flex" align="middle">
          <Col xs={24}>
            <img
              className="banner pd-margin-bottom-xl"
              src={`${process.env.PUBLIC_URL}/img/banner-2.jpg`}
              alt="Banner"
            />
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="center">
          <Col xs={24} md={4}>
            <img
              className="pd-margin-bottom-md product"
              src={`${process.env.PUBLIC_URL}/img/products/handle-bag.jpg`}
              alt="Wilco Handle Bag"
            />
            <div className="pd-products-title">Tas Handle Lipat Samping</div>
          </Col>

          <Col xs={24} md={{ span: 4, offset: 1 }}>
            <img
              className="pd-margin-bottom-md product"
              src={`${process.env.PUBLIC_URL}/img/products/oval-bag.jpg`}
              alt="Wilco Oval 1"
            />
            <div className="pd-products-title">Tas Oval</div>
          </Col>

          <Col xs={24} md={{ span: 4, offset: 1 }}>
            <img
              className="pd-margin-bottom-md product"
              src={`${process.env.PUBLIC_URL}/img/products/box-jahit.jpg`}
              alt="Box Jahit"
            />
            <div className="pd-products-title">Tas Handle Box Jahit</div>
          </Col>

          <Col xs={24} md={{ span: 4, offset: 1 }}>
            <img
              className="pd-margin-bottom-md product"
              src={`${process.env.PUBLIC_URL}/img/products/handle-kresek.jpg`}
              alt="Wilco Handle Kresek"
            />
            <div className="pd-products-title">Tas Kantong Kresek</div>
          </Col>
        </Row>

        <Row className="pd-margin-bottom-lg pd-margin-top-2xl">
          <Col xs={24} md={{ span: 20, offset: 2 }}>
            <div className="pd-products-title">Pilihan Warna</div>
          </Col>
          <Col xs={24}>
            <Carousel ref={(slider) => (this.slider = slider)}>
              {colorOptions.map((color, i) => (
                <img
                  key={i}
                  className="colored-bag"
                  src={`${
                    process.env.PUBLIC_URL
                  }/img/products/color-options/${i + 1}.jpg`}
                  alt="Wilco Bag"
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row className="pd-margin-bottom-lg" type="flex" justify="center">
          {colorOptions.map((color, i) => (
            <div
              key={i}
              className="color-option"
              style={{ backgroundColor: color }}
              onClick={this.handleColorClick(i)}
            />
          ))}
        </Row>
      </div>
    )
  }
}

export default ProductsPage
