import React from 'react'
import { Divider, Row, Col } from 'antd'
import './style.scss'

class FAQPage extends React.Component {
  render() {
    return (
      <Row className="pd-faq">
        <Col xs={24} lg={{ span: 20, offset: 2 }} className="pd-margin-top-xl">
          <div className="pd-faq-title">FAQ</div>
        </Col>
        <Col xs={24} lg={{ span: 12, offset: 2 }} className="pd-faq-left">
          <div className="pd-faq-wrapper">
            <div className="pd-faq-wrapper-question">
              Why choose Spunbond bag?
            </div>
            <div className="pd-faq-wrapper-answer">
              We sell bags made of spunbond/ non woven materials with a variety
              of colors and can also be printed in accordance with the wishes
              and promotional activities of your business. We provide services
              for retailers and wholesalers. Cheap and competitive prices.
              Quality assurance and service and delivery throughout Indonesia.
              The advantages of waterproof bags, durable and not easily tangled.
            </div>
          </div>
          <div className="pd-faq-wrapper">
            <div className="pd-faq-wrapper-question">
              Who is suitable to use this Spunbond bag?
            </div>
            <div className="pd-faq-wrapper-answer">
              Most of the spunbond bag users are companies to do marketing
              because it's strong and can be reusable.
            </div>
            <div className="pd-faq-wrapper-answer">
              As a souvernir bag and birthday bag. With this unique pressed
              printed bag, this kind of bag is loved by a lot of people.
            </div>
          </div>
        </Col>
        <Col xs={0} lg={{ span: 1, offset: 1 }}>
          <Divider type="vertical" style={{ height: 540 }} />
        </Col>
        <Col xs={24} lg={6}>
          <div className="pd-faq-extras">
            <div>
              <div className="pd-faq-extras-title">Ask us anything</div>
              <span>
                Please reach out to us with any question you have at
                cs@wilcospunbound.com Or drop us a message via Whatsapp
                (0812-1962-4222)
              </span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default FAQPage
