import React from 'react'
import { Row, Col } from 'antd'
import './style.scss'

function Footer() {
  return (
    <Row type="flex" justify="space-around" className="pd-footer">
      <Col xs={24}>Copyright © 2019 Wilco Spunbond. All rights reserved.</Col>
      <Col xs={24}>
        <span className="pd-footer-sub">
          Call us at (021–2952-6883) or contact us via{' '}
          <a
            className="link"
            href="https://wa.me/6281219624222"
            target="_blank"
            rel="noopener noreferrer">
            WhatsApp
          </a>
        </span>
      </Col>
    </Row>
  )
}

export default Footer
