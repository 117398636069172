import React from 'react'
import { Carousel, Row, Col } from 'antd'

import './style.scss'

class AboutPage extends React.Component {
  render() {
    return (
      <div className="pd-about">
        <Row>
          <Col xs={24}>
            <img
              src={`${process.env.PUBLIC_URL}/img/productions/1.JPG`}
              alt="Production"
            />
          </Col>
          <Col
            xs={24}
            lg={{ span: 20, offset: 2 }}
            className="pd-margin-top-xl">
            <div className="pd-about-title">Tentang Kami</div>
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-content">
              Wilco spunbond berdiri pada tahun 2013 dengan mesin produksi
              canggih yang mampu membuat model tas spunbond terkini. Kami
              memiliki kapasitas produksi 5,000,000pcs / bulan. Kami telah
              memproduksi berbagai jenis tas kain, handle maupun oval dengan
              design yang dapat disesuaikan dengan kebutuhan anda.
            </div>
            <div className="pd-about-content">
              Ada juga beberapa model tas dengan inovasi baru yang hanya
              tersedia di Wilco. Model tas dapat di liat di bagian katalog
              website ini. Wilco spunbond mengutamakan kualitas, pelayanan dan
              kepuasan pelanggan. Kami merasa bertanggung jawab untuk tetap
              menjaga kelestarian lingkungan demi generasi berikutnya.
            </div>
            <div className="pd-about-content">
              <h3>Nilai Kami</h3>
              <strong>"MILKK"</strong> Motivasi, Integritas, Loyal, Komitmen,
              Kerjasama (Motivation, Intengrity, Loyalty, Commitment,
              Cooperation)
            </div>
          </Col>
          <Col xs={24} lg={{ span: 9, offset: 1 }}>
            <Carousel autoplay>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/2.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/3.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/4.JPG`}
                  alt="Production"
                />
              </div>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/productions/5.JPG`}
                  alt="Production"
                />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">Visi</div>
            <div className="pd-about-content">
              “Menjadi perusahaan produksi tas kain yang berskala internasional
              dan terdepan dalam inovasi dan layanan terbaik”
            </div>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">Misi</div>
            <div className="pd-about-content">
              <ol className="pd-about-content__list">
                <li>
                  Menghasilkan produk bermutu dan layanan yang terbaik untuk
                  seluruh pelanggan dalam skala kecil ataupun besar
                </li>
                <li>
                  Meningkatkan kesejahteraan dan pengembangan diri untuk setiap
                  karyawan
                </li>
                <li>
                  Pengembangan pasar yang tinggi setiap tahun dan memiliki jalur
                  distribusi yang solid ditingkat nasional maupun internasional.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className="pd-margin-top-md">
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-about-subtitle">
              Mengapa Memilih Wilco Spunbond?
            </div>
            <div className="pd-about-content">
              <ol className="pd-about-content__list">
                <li>
                  Kami menjual tas berbahan spunbond dengan berbagai aneka warna
                  dan juga bisa disablon sesuai dengan keinginan dan kegiatan
                  promosi usaha anda
                </li>
                <li>Kami melayani ritel, grosir dan partai besar</li>
                <li>Harga murah dan bersaing</li>
                <li>
                  Jaminan kualitas dan pelayanan serta pengiriman ke seluruh
                  Indonesia
                </li>
                <li>
                  Kelebihan tas tahan air, kuat dan bisa bolak-balik serta
                  tidak mudah kusut
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default AboutPage
