import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col, Icon } from 'antd'
import { LangContext } from 'App'

import './style.scss'

function Menu() {
  const [menuVisible, setMenuVisible] = useState(false)
  const toogleMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const ButtonGroup = Button.Group

  const { lang, toggleLang } = useContext(LangContext)

  return (
    <Row className="pd-header" type="flex" align="middle">
      <Col
        xs={{ span: 7, offset: 1 }}
        md={{ span: 4, offset: 2 }}
        lg={{ span: 4, offset: 2 }}>
        <Link to="/">
          <img
            className="pd-header-logo"
            src={`${process.env.PUBLIC_URL}/img/logo/wilco.png`}
            alt="Wilco Logo"
          />
        </Link>
      </Col>
      <Col xs={0} md={16} lg={16}>
        <div className="pd-header-menu">
          <Link to="/" className="pd-header-menu-item">
            Home
          </Link>
          <Link to="/products" className="pd-header-menu-item">
            Products
          </Link>
          <Link to="/contact" className="pd-header-menu-item">
            Contact Us
          </Link>
          <Link to="/faq" className="pd-header-menu-item">
            FAQ
          </Link>
          <Link to="/about" className="pd-header-menu-item">
            About
          </Link>
          <ButtonGroup>
            <Button disabled={lang === 'en'} onClick={toggleLang}>
              EN
            </Button>
            <Button disabled={lang === 'id'} onClick={toggleLang}>
              ID
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col xs={15} md={0} lg={0} className="hamburger-menu">
        <Icon type="menu" onClick={toogleMenu} />
      </Col>
      {menuVisible && (
        <div className="pd-header-menu--mobile">
          <div className="header">
            <Col className="close-btn" onClick={toogleMenu}>
              <Icon type="close" />
            </Col>
            <Col className="text-center" xs={24}>
              Menu
            </Col>
          </div>
          <div onClick={toogleMenu}>
            <Link to="/" className="item">
              Home
            </Link>
            <Link to="/products" className="item">
              Products
            </Link>
            <Link to="/contact" className="item">
              Contact Us
            </Link>
            <Link to="/faq" className="item">
              FAQ
            </Link>
            <Link to="/about" className="item">
              About
            </Link>
            <ButtonGroup>
              <Button disabled={lang === 'en'} onClick={toggleLang}>
                EN
              </Button>
              <Button disabled={lang === 'id'} onClick={toggleLang}>
                ID
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </Row>
  )
}

export default Menu
