import React from 'react'
import { Divider, Row, Col } from 'antd'
import './style.scss'

class FAQPage extends React.Component {
  render() {
    return (
      <Row className="pd-faq">
        <Col xs={24} lg={{ span: 20, offset: 2 }} className="pd-margin-top-xl">
          <div className="pd-faq-title">FAQ</div>
        </Col>
        <Col xs={24} lg={{ span: 12, offset: 2 }} className="pd-faq-left">
          <div className="pd-faq-wrapper">
            <div className="pd-faq-wrapper-question">
              Kenapa memilih tas yang terbuat dari spunbond?
            </div>
            <div className="pd-faq-wrapper-answer">
              Tas kain spunbond terbuat dari bahan yang kuat dengan proses
              modern sehingga tas akan lebih tahan lama, bisa dipakai berulang
              kali dan dapat di daur ulang/ GO GREEN. Bahan spunbond juga tidak
              mengandung bahan kimia yang berbahaya
            </div>
          </div>
          <div className="pd-faq-wrapper">
            <div className="pd-faq-wrapper-question">
              Apakah tas furing sama dengan tas kain spunbond?
            </div>
            <div className="pd-faq-wrapper-answer">
              Tas furing dan tas spunbond adalah barang yang sama. Tas spunbond
              juga sering di kenal dengan tas promosi, tas modern, tas souvenir,
              tas kain, goodie bag dsb.
            </div>
          </div>
          <div className="pd-faq-wrapper">
            <div className="pd-faq-wrapper-question">
              Siapakah yang cocok untuk menggunakan tas kain spunbond?
            </div>
            <div className="pd-faq-wrapper-answer">
              Sebagian besar penggunaan tas spunbond adalah di perusahaan
              sebagai bahan media untuk promosi di karenakan oleh penggunaannya
              yang awet dan dapat di pakai berulang kali.
            </div>
            <div className="pd-faq-wrapper-answer">
              Sebagai tas souvenir dan ulang tahun. Dengan keindahan tas unik
              ini yang dapat di sablon/ di cetak, tas ini banyak di gemari
              masyarakat.
            </div>
          </div>
        </Col>
        <Col xs={0} lg={{ span: 1, offset: 1 }}>
          <Divider type="vertical" style={{ height: 540 }} />
        </Col>
        <Col xs={24} lg={6}>
          <div className="pd-faq-extras">
            <div>
              <div className="pd-faq-extras-title">Tanya kami apa saja</div>
              <span>
                Kontak kami dengan pertanyaan apa saja di cs@wilcospunbound.com
                atau tinggalkan pesan melalui Whatsapp (0812-1962-4222)
              </span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default FAQPage
