import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd'

import './style.scss'

class HomePage extends React.Component {
  render() {
    return (
      <>
        <Row className="pd-introduction">
          <Col xs={24}>
            <img
              className="pd-margin-bottom-xl"
              src={`${process.env.PUBLIC_URL}/img/banner-1.jpg`}
              alt="Banner"
            />
          </Col>
          <Col xs={{ span: 18, offset: 3 }} lg={{ span: 5, offset: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/sample-product.png`}
              alt="Sample Product"
            />
          </Col>
          <Col xs={24} lg={{ span: 14, offset: 1 }}>
            <h3>Pabrik Tas Non Woven</h3>
            <span>
              Wilco spunbond berdiri pada tahun 2013 dengan mesin produksi
              canggih yang mampu membuat model tas spunbond terkini. Kami telah
              memproduksi berbagai jenis tas kain, handle maupun oval dengan
              design yang dapat disesuaikan dengan kebutuhan anda. Wilco
              spunbond mengutamakan kualitas, pelayanan dan kepuasan pelanggan.
              Kami merasa bertanggung jawab untuk tetap menjaga kelestarian
              lingkungan demi generasi berikutnya.
            </span>
            <div className="pd-margin-top-lg">
              <Link to="/about">
                <Button size="large">Tentang Kami</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" className="pd-quality">
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/eco.png`}
                  style={{ width: 30 }}
                  alt="Kualitas"
                />
              </div>
              <div>
                <h1>Daur Ulang</h1>
                <span>
                  Penggunaan tas spunbond ini cocok untuk konsep daur ulang.
                  Kita perlu mengurangi penggunaan kantong plastik dan
                  menggunakan tas yang bisa dipakai lagi.
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  className="pd-margin-top-sm"
                  src={`${process.env.PUBLIC_URL}/img/icon/durable.png`}
                  style={{ width: 30 }}
                  alt="Kualitas"
                />
              </div>
              <div>
                <h1>Tahan Lama</h1>
                <span>
                  Tas non-woven bisa digunakan berulang kali karena kekuatan dan
                  ketahanannya.
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={6}>
            <div className="pd-quality-item">
              <div className="pd-margin-right-md">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/waterproof.png`}
                  style={{ width: 30 }}
                  alt="Kualitas"
                />
              </div>
              <div>
                <h1>Tidak Beracun</h1>
                <span>
                  Bahan non-woven sangat umum digunakan karena mereka tidak
                  beracun dan tidak ada bakteria karena dibuat dari bahan
                  spunbond.
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pd-traction">
          <Col xs={24}>
            <div className="pd-traction-title">
              <h2 className="pd-margin-top-lg  pd-margin-bottom-xl">
                Dipercaya oleh 1000+ Merk
              </h2>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/adidas.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/bca.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/pizzahut.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/puma.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/samsung.png`}
              alt="Brand Logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/traction/shell.png`}
              alt="Brand Logo"
            />
          </Col>
        </Row>
        <Row className="pd-product">
          <Col xs={24} lg={{ span: 8, offset: 2 }}>
            <img
              className="pd-margin-bottom-xl"
              src={`${process.env.PUBLIC_URL}/img/dyob.jpg`}
              alt="Design Your Own Bag"
            />
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 2 }}>
            <div className="pd-product-title">DYOB</div>
            <div className="pd-product-subtitle pd-margin-bottom-lg">
              Design Your Own Bag
            </div>
            <span>
              Kami sudah memproduksi berbagai tas non-woven dengan berbagai
              design dan ukuran yang bisa disesuaikan dengan kebutuhan anda. Ada
              juga berbagai model dengan inovasi baru yang hanya terdapat di
              Wilco.
            </span>
            <div className="pd-margin-top-lg">
              <Link to="/contact">
                <Button size="large">Buat Quotation</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default HomePage
